import React, { useState, useEffect } from "react";
import Fuse from "fuse.js";
import io from "socket.io-client";
import "../css/sheet.css";
import * as XLSX from "xlsx";
import axios from "axios";
import { apiInstance, socket } from "../config/config"; // Adjust the import path according to your project structure

import NavigationBar from "./Navbar"; // Adjust the import path according to your file structure

// Initialize socket connection outside the component to prevent reconnecting on every render

function Sheet() {
  const [invoices, setInvoices] = useState([]);
  const [allInvoices, setAllInvoices] = useState([]); // Keep track of all invoices
  const [totalAmount, setTotalAmount] = useState(0);
  const [artists, setArtists] = useState([]);

  const [filterYear, setFilterYear] = useState("");
  const [filterMonth, setFilterMonth] = useState("");
  const fetchInvoices = async (preserveFilters = false) => {
    try {
      const response = await apiInstance.get("/api/invoices");
      const fetchedInvoices = response.data;
      if (preserveFilters && filterYear && filterMonth) {
        // Apply the existing filter when preserveFilters is true
        const filteredInvoices = fetchedInvoices.filter((invoice) => {
          const invoiceDate = new Date(invoice.startDate);
          const invoiceYear = invoiceDate.getFullYear();
          const invoiceMonth = invoiceDate.getMonth() + 1; // JavaScript months are 0-indexed
          return (
            invoiceYear.toString() === filterYear &&
            invoiceMonth.toString() === filterMonth
          );
        });
        setAllInvoices(fetchedInvoices);
        setInvoices(filterInvoicesByHalfPayment(filteredInvoices));
      } else {
        setAllInvoices(fetchedInvoices);
        setInvoices(filterInvoicesByHalfPayment(fetchedInvoices));
      }
    } catch (error) {
      console.error("Error fetching invoices:", error);
    }
  };


  useEffect(() => {
    // Fetch invoices only once on component mount
    fetchArtists();

    fetchInvoices();

    // Setup socket listeners
    socket.on("connect", () => {
      console.log("Connected to server with id:", socket.id);
    });

    socket.on("invoicePaid", (invoice) => {
      const newAllInvoices = [...allInvoices, invoice];
      setAllInvoices(newAllInvoices);
      setInvoices(filterInvoicesByHalfPayment(newAllInvoices));
    });

    // Cleanup on component unmount
    return () => {
      socket.off("invoicePaid");
      socket.off("connect");
    };
  }, []); // Empty dependency array ensures this effect only runs once

  useEffect(() => {
    // Recalculate total amount when invoices change
    setTotalAmount(calculateTotalAmount(invoices));
  }, [invoices]);

  const calculateTotalAmount = (invoices) => {
    return invoices.reduce(
      (total, invoice) => total + (invoice.amountearned || 0),
      0
    );
  };

  const exportToExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(invoices);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Invoices");
    XLSX.writeFile(workbook, "invoices.xlsx");
  };

  const handleFilter = () => {
    fetchInvoices(true); // Preserve the filters when fetching invoices
  };

  const clearFilter = () => {
    setFilterYear("");
    setFilterMonth("");
    setInvoices(filterInvoicesByHalfPayment(allInvoices));
  };

  const filterInvoicesByHalfPayment = (invoices) => {
    // Options for Fuse.js search
    const options = {
      // Specify which keys to search in
      keys: ["lineItems.description"],
      // Threshold for matching: lower means more strict, higher means more loose
      threshold: 0.3, // Adjust based on desired sensitivity
    };

    // Initialize Fuse with invoices and options
    const fuse = new Fuse(invoices, options);

    // Perform search for "half payment" - you might adjust this term based on your needs
    const result = fuse.search("half payment");

    // Extract matched invoices from the result
    const matchedInvoices = result.map((item) => item.item);

    // Sort matchedInvoices by startDate in ascending order
    matchedInvoices.sort((a, b) => {
      const dateA = new Date(a.startDate);
      const dateB = new Date(b.startDate);
      return dateA - dateB;
    });

    return matchedInvoices;
  };

  const getYearsOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 10 }, (_, index) => currentYear - index);
    return years.map((year) => (
      <option key={year} value={year}>
        {year}
      </option>
    ));
  };

  // Month Options
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthsOptions = monthNames.map((name, index) => (
    <option key={name} value={index + 1}>
      {name}
    </option>
  ));

  const handleUpdateInvoice = async (invoiceId, updatedFields) => {
    try {
      await apiInstance.post(`/api/invoices/${invoiceId}`, updatedFields);

      // Update the specific invoice in the state
      setInvoices((prevInvoices) =>
        prevInvoices.map((invoice) =>
          invoice._id === invoiceId ? { ...invoice, ...updatedFields } : invoice
        )
      );
      setAllInvoices((prevAllInvoices) =>
        prevAllInvoices.map((invoice) =>
          invoice._id === invoiceId ? { ...invoice, ...updatedFields } : invoice
        )
      );
    } catch (error) {
      console.error("Error updating invoice:", error.response?.data || error);
    }
  };


  const handlePaymentTypeChange = async (invoiceId, paymentType) => {
    const updatedFields = {
      paymentType: paymentType,
    };

    await handleUpdateInvoice(invoiceId, updatedFields);
  };

  const fetchArtists = async () => {
    try {
      const response = await apiInstance.get("/api/auth/artists", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      setArtists(response.data.map((artist) => artist.username)); // Adjust according to your artist document structure
    } catch (error) {
      console.error("Failed to fetch artists", error);
    }
  };

  return (
    <div className="admin-dashboard-container">
      <NavigationBar />
      <div className="sheet-table-container">
        <div className="sheet-filter">
          <select
            value={filterYear}
            onChange={(e) => setFilterYear(e.target.value)}
            className="sheet-filter-select"
          >
            <option value="">Select Year</option>
            {getYearsOptions()}
          </select>
          <select
            value={filterMonth}
            onChange={(e) => setFilterMonth(e.target.value)}
            className="sheet-filter-select"
          >
            <option value="">Select Month</option>
            {monthsOptions}
          </select>
          <button className="sheet-filter-btn" onClick={handleFilter}>
            Filter
          </button>
          <button className="clear-filter-btn" onClick={clearFilter}>
            Clear Filter
          </button>
        </div>
        <button className="export-btn" onClick={exportToExcel}>
          Export to Excel
        </button>
      </div>
      <table className="sheet-table">
        <thead>
          <tr>
            <th>Project Name</th>
            <th>Email</th>
            <th>Amount Paid</th>
            <th>Payment Type</th>
            <th>Project Status</th>
            <th>Assign</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>File Requested</th>
          </tr>
        </thead>
        <tbody>
          {invoices.map((invoice, index) => (
            <tr key={index}>
              <td>{invoice.projectName}</td>
              <td>{invoice.customerEmail}</td>
              <td>${invoice.amountearned / 100}</td>
              <td>
                <select
                  value={invoice.paymentType}
                  onChange={(e) =>
                    handlePaymentTypeChange(invoice._id, e.target.value)
                  }
                  className={`payment-type-select ${invoice.paymentType === "Half"
                      ? "red-background"
                      : invoice.paymentType === "Full"
                        ? "green-background"
                        : "refund-background" // Optional styling for Refund
                    }`}
                >
                  <option value="Half">Half</option>
                  <option value="Full">Full</option>
                  <option value="Refund">Refund</option> {/* New Refund option */}
                </select>
              </td>

              <td>
                <select
                  value={invoice.projectStatus}
                  onChange={(e) =>
                    handleUpdateInvoice(invoice._id, {
                      projectStatus: e.target.value,
                    })
                  }
                  className={`custom-select ${invoice.projectStatus === "Not Assigned"
                      ? "grey-background"
                      : invoice.projectStatus === "Work In Progress"
                        ? "red-background"
                        : "green-background"
                    }`}
                >
                  <option value="Not Assigned">NA</option>
                  <option value="Work In Progress">WIP</option>
                  <option value="Done">Done</option>
                </select>
              </td>

              <td>
                <select
                  name="assignedTo"
                  value={invoice.assign}
                  onChange={(e) =>
                    handleUpdateInvoice(invoice._id, { assign: e.target.value })
                  }
                  required
                  className={`custom-select ${!invoice.assign || invoice.assign === "None"
                      ? "grey-background"
                      : "random-background"
                    }`}
                >
                  <option value="">Select</option>
                  {artists.map((artist, index) => (
                    <option key={index} value={artist}>
                      {artist}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                {new Date(invoice.startDate).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })}
              </td>
              <td>
                {new Date(invoice.endDate).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })}
              </td>
              <td>
                <select
                  value={invoice.fileRequested}
                  onChange={(e) =>
                    handleUpdateInvoice(invoice._id, {
                      fileRequested: e.target.value,
                    })
                  }
                  className={`custom-select ${invoice.fileRequested === "Null"
                      ? "grey-background"
                      : invoice.fileRequested === "Requested"
                        ? "red-background"
                        : "green-background"
                    }`}
                >
                  <option value="Null">Null</option>
                  <option value="Requested">Requested</option>
                  <option value="Delivered">Delivered</option>
                </select>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="total-amount-sheet">
        <strong>Total Amount Paid: ${totalAmount / 100}</strong>
      </div>
    </div>
  );
}

export default Sheet;
