import React from 'react';
import { Navigate } from 'react-router-dom';


const ProtectedRoute = ({ children, allowedRoles }) => {
  const userRole = localStorage.getItem('role'); // Assume role is stored in localStorage
  if (!allowedRoles.includes(userRole)) {
    return <Navigate to="/" replace />; // Redirect to login or unauthorized page
  }
  return children;
};

export default ProtectedRoute;
