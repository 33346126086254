import React, { useState, useEffect, useMemo } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import "../css/AdminDashboard.css";
import FileUploadContainer from "./FileUploadContainer"; // Adjust the import path according to your file structure
import Linkify from "react-linkify";
import NavigationBar from "./Navbar"; // Adjust the import path according to your file structure
import { apiInstance } from "../config/config"; // Adjust the import path according to your project structure

const AdminDashboard = () => {
  const [projects, setProjects] = useState([]);
  const [artists, setArtists] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [tempCompletions, setTempCompletions] = useState({});

  const [formData, setFormData] = useState({
    projectName: "",
    clientEmail: "",
    description: "",
    assignedTo: "",
    startDate: "",
    endDate: "",
  });

  useEffect(() => {
    fetchProjects();
    fetchArtists();
  }, []);

  const fetchProjects = async () => {
    try {
      const response = await apiInstance.get("/api/projects", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      setProjects(response.data);
    } catch (error) {
      console.error("Failed to fetch projects", error);
    }
  };

  const fetchArtists = async () => {
    try {
      const response = await apiInstance.get("/api/auth/artists", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      setArtists(response.data.map((artist) => artist.username));
    } catch (error) {
      console.error("Failed to fetch artists", error);
    }
  };
  const addWorkingDays = (startDate, numberOfDays) => {
    let date = new Date(startDate);
    while (numberOfDays > 0) {
      date = new Date(date.setDate(date.getDate() + 1)); // Add a day
      if (date.getDay() !== 0 && date.getDay() !== 6) {
        // Check if it's not Saturday or Sunday
        numberOfDays--;
      }
    }
    return date;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedFormData = { ...formData, [name]: value };

    if (name === "startDate") {
      const endDate = addWorkingDays(value, 15);
      updatedFormData = {
        ...updatedFormData,
        endDate: endDate.toISOString().slice(0, 10),
      }; // Format to 'yyyy-mm-dd'
    }

    setFormData(updatedFormData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.projectName || !formData.clientEmail || !formData.assignedTo) {
      toast.warn("Please fill out all required fields.");
      return;
    }
  
    try {
      // Prepare the invoice data for the invoices collection
      const invoiceData = {
        stripeInvoiceId: null,  // No stripe ID for manual projects
        customerName: formData.projectName,  // Using projectName as customerName
        customerEmail: formData.clientEmail,
        amountearned: 0,  // Default value
        amountPaid: 0,  // Default value
        paymentType: "Half",  // Marking it as half payment
        projectStatus: "Not Assigned",  // Default status
        description: "(Half Payment)",  // Append "Half Payment" to description
        assign: formData.assignedTo,
        startDate: formData.startDate,
        projectName: formData.projectName,
        lineItems: [
          {
            description: "(Half Payment)",  // Append "Half Payment" to line item description
            amount: 0,  // Default amount
            quantity: 1,  // Default quantity
          },
        ],
        endDate: formData.endDate,
        fileRequested: "Null",  // Default file request status
      };
  
      // Prepare the project data for the projects collection
      const projectData = {
        projectName: formData.projectName,
        clientEmail: formData.clientEmail,
        description: formData.description,  // Append "Half Payment" to project description
        assignedTo: formData.assignedTo,
        startDate: formData.startDate,
        endDate: formData.endDate,
        comments: [],  // You can initialize comments as empty array
        completionPercentage: 0,  // Default completion percentage
        lastUploadedDate: "",  // Default lastUploadedDate
      };
  
      // Post the data to the invoices collection
      const invoiceResponse = await apiInstance.post("/api/invoices", invoiceData, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
     
      // Post the data to the projects collection
      const projectResponse = await apiInstance.post("/api/projects", projectData, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
     
      if (invoiceResponse.status === 200 && projectResponse.status === 201) {
        toast.success("Project added to Annual Report and Projects collection.");
  
        // Update the local state with the new project
        setProjects((prevProjects) => [...prevProjects, projectResponse.data]);
  
        // Reset the form after submission
        setFormData({
          projectName: "",
          clientEmail: "",
          description: "",
          assignedTo: "",
          startDate: "",
          endDate: "",
        });
      }
    } catch (error) {
      console.error("Failed to create project or add to Annual Report.", error);
      toast.error("Failed to add project to Annual Report or Projects.");
    }
  };
  
  
  

  const filteredProjects = useMemo(
    () =>
      projects.filter(
        (project) =>
          project.projectName
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          project.description.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    [projects, searchTerm]
  );

  const addComment = async (projectId, comment) => {
    // Call API to add comment to project
    const response = await apiInstance.post(
      `/api/projects/addComment`,
      { projectId, comment },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    // Refresh projects to show new comment
    fetchProjects();
  };

  const updateAssignedArtist = async (projectId, artistId) => {
    // Call API to update assigned artist for a project
    const response = await apiInstance.post(
      `/api/projects/updateArtist`,
      { projectId, artistId },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    // Refresh projects to show updated artist
    fetchProjects();
  };
  const customComponentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  );
  const handleCompletionChange = (projectId, value) => {
    setTempCompletions((prev) => ({ ...prev, [projectId]: value }));
  };

  const updateCompletion = async (projectId) => {
    const newPercentage = tempCompletions[projectId];
    if (newPercentage != null) {
      // Check if there's a value for this project
      try {
        const response = await apiInstance.post(
          `/api/projects/${projectId}/completion`,
          {
            completionPercentage: newPercentage,
          }
        );
        setProjects((prevProjects) =>
          prevProjects.map((project) =>
            project._id === projectId
              ? { ...project, completionPercentage: newPercentage }
              : project
          )
        );
        toast.success("Completion updated successfully");
        // Optionally clear the local change after updating
        setTempCompletions((prev) => {
          const newState = { ...prev };
          delete newState[projectId];
          return newState;
        });
      } catch (error) {
        console.error("Failed to update completion percentage", error);
        toast.error("Failed to update completion percentage");
      }
    }
  };

  return (
    <div className="admin-dashboard-container">
      <NavigationBar />
      <ToastContainer />
      <div></div>
      <div className="content-wrapper">
        <form onSubmit={handleSubmit} className="admin-form">
          <input
            name="projectName"
            value={formData.projectName}
            onChange={handleChange}
            placeholder="Project Name"
            type="text"
            className="input"
          />
          <input
            name="clientEmail"
            value={formData.clientEmail}
            onChange={handleChange}
            placeholder="Client Email"
            type="email" // Updated to specify email type
            className="input"
          />
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            placeholder="Description"
            className="admin-textarea"
          ></textarea>
          <select
            name="assignedTo"
            value={formData.assignedTo}
            onChange={handleChange}
            required
            className="select"
          >
            <option value="">Please select an artist</option>
            {artists.map((artist, index) => (
              <option key={index} value={artist}>
                {artist}
              </option>
            ))}
          </select>
          <input
            name="startDate"
            type="date"
            value={formData.startDate}
            onChange={handleChange}
            className="input"
          />
          <input
            name="endDate"
            type="date"
            value={formData.endDate}
            onChange={handleChange}
            className="input"
          />
          <button type="submit" className="admin-submit-button">
            Create Project
          </button>
        </form>
        <div className="file-upload-container">
          <FileUploadContainer />
        </div>
      </div>

      <div className="admin-projects-table-wrap">
        <input
          type="text"
          placeholder="Search projects..."
          className="input search-input" // Ensure you have styles for 'search-input' or use existing 'input' styles
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <table className="admin-projects-table">
          <thead>
            <tr>
              <th>Project Name</th>
              <th>Client Email</th>
              <th>Description</th>
              <th>Assigned To</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Completion %</th>
            </tr>
          </thead>
          <tbody>
            {filteredProjects.map((project) => (
              <tr key={project._id}>
                <td
                  data-label="Project Name:"
                  style={{ whiteSpace: "pre-line", textAlign: "left" }}
                >
                  {project.projectName}
                </td>
                <td
                  data-label="Client Email:"
                  style={{ whiteSpace: "pre-line", textAlign: "left" }}
                >
                  {project.clientEmail}
                </td>
                <td
                  data-label="Description:"
                  style={{ whiteSpace: "pre-line", textAlign: "left" }}
                >
                  <Linkify componentDecorator={customComponentDecorator}>
                    {project.description}
                    {project.comments && project.comments.length > 0 && (
                      <ul style={{ paddingLeft: 0, listStyle: "none" }}>
                        {project.comments.map((comment, index) => (
                          <li key={index} style={{ marginTop: "10px" }}>
                            <strong>Comment {index + 1}:</strong>
                            <br />
                            {comment.text}
                          </li>
                        ))}
                      </ul>
                    )}
                    <div
                      style={{
                        marginTop: "10px",
                        whiteSpace: "pre-line",
                        textAlign: "left",
                      }}
                    >
                      <textarea
                        placeholder="Add a comment..."
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && !e.shiftKey) {
                            e.preventDefault();
                            if (e.currentTarget.value.trim() !== "") {
                              addComment(project._id, e.currentTarget.value);
                              e.currentTarget.value = ""; // Reset textarea after comment
                            }
                          }
                        }}
                        style={{ width: "100%", height: "50px" }}
                      />
                    </div>
                  </Linkify>
                </td>
                <td
                  data-label="Assigned To:"
                  style={{ whiteSpace: "pre-line", textAlign: "left" }}
                >
                  <select
                    onChange={(e) =>
                      updateAssignedArtist(project._id, e.target.value)
                    }
                    value={project.assignedTo || "Null"}
                  >
                    <option value="Null">Please select</option>
                    {artists.map((artist, index) => (
                      <option key={index} value={artist}>
                        {artist}
                      </option>
                    ))}
                  </select>
                </td>
                <td
                  data-label="Start Date:"
                  style={{ whiteSpace: "pre-line", textAlign: "left" }}
                >
                  {new Date(project.startDate).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })}
                </td>
                <td
                  data-label="End Date:"
                  style={{ whiteSpace: "pre-line", textAlign: "left" }}
                >
                  {new Date(project.endDate).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })}
                </td>
                <td data-label="completion %"
                style={{ whiteSpace: "pre-line", textAlign: "left" }}
                >
                  <input
                    type="number"
                    value={
                      tempCompletions[project._id] !== undefined
                        ? tempCompletions[project._id]
                        : project.completionPercentage
                    }
                    onChange={(e) =>
                      handleCompletionChange(project._id, e.target.value)
                    }
                    min="0"
                    max="100"
                    className="completion-input"
                  />
                  <button
                    onClick={() => updateCompletion(project._id)}
                    className="update-button"
                  >
                    Update
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* {projects.map(project => (
        <ArtistDashboard key={project._id} project={project} />
      ))} */}
    </div>
  );
};

export default AdminDashboard;
