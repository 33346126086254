import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import AdminDashboard from './components/AdminDashboard';
import ArtistDashboard from './components/ArtistDashboard';
// Import additional components
import Attendance from './components/Attendance';
import PaySlip from './components/payslip';
import Leave from './components/Leave';
import AnnualReport from './components/AnnualReport';
import Sheet from './components/Sheet';
import Users from './components/Users';
import ProtectedRoute from './components/ProtectedRoute'; 

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        
        {/* Admin Dashboard should only be accessible to admin */}
        <Route
          path="/admin-dashboard"
          element={
            <ProtectedRoute allowedRoles={['admin']}>
              <AdminDashboard />
            </ProtectedRoute>
          }
        />

        {/* Artist Dashboard should only be accessible to artists */}
        <Route
          path="/artist-dashboard"
          element={
            <ProtectedRoute allowedRoles={['artist']}>
              <ArtistDashboard />
            </ProtectedRoute>
          }
        />

        {/* Other protected routes based on the roles */}
        <Route
          path="/attendance"
          element={
            <ProtectedRoute allowedRoles={['admin', 'artist']}>
              <Attendance />
            </ProtectedRoute>
          }
        />
        <Route
          path="/payslip"
          element={
            <ProtectedRoute allowedRoles={['admin', 'artist']}>
              <PaySlip />
            </ProtectedRoute>
          }
        />
        <Route
          path="/leave"
          element={
            <ProtectedRoute allowedRoles={['admin', 'artist']}>
              <Leave />
            </ProtectedRoute>
          }
        />
        <Route
          path="/annual"
          element={
            <ProtectedRoute allowedRoles={['admin']}>
              <AnnualReport />
            </ProtectedRoute>
          }
        />
        <Route
          path="/sheet"
          element={
            <ProtectedRoute allowedRoles={['admin']}>
              <Sheet />
            </ProtectedRoute>
          }
        />
        <Route
          path="/users"
          element={
            <ProtectedRoute allowedRoles={['admin']}>
              <Users />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
