import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { apiInstance } from "../config/config"; // Adjust the import path according to your project structure
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ProjectDropzone = ({ projectId, projectEmail, projectName, onUploadSuccess }) => { // Add onUploadSuccess prop
  const [selectedFiles, setSelectedFiles] = useState({});

  const onDrop = useCallback(
    (acceptedFiles) => {
      handleFilesAdded(acceptedFiles, projectId);
    },
    [projectId]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  const handleFilesAdded = (acceptedFiles, projectId) => {
    setSelectedFiles((prevFiles) => ({
      ...prevFiles,
      [projectId]: acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ),
    }));
  };

  const removeFile = (fileName, projectId, e) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedFiles((prevFiles) => ({
      ...prevFiles,
      [projectId]: prevFiles[projectId].filter(
        (file) => file.name !== fileName
      ),
    }));
  };

  const uploadFiles = async (e, projectId, projectEmail, projectName) => {
    e.preventDefault();
    e.stopPropagation();

    const filesToUpload = selectedFiles[projectId] || [];
    const formData = new FormData();
    filesToUpload.forEach((file) => {
      formData.append("files", file);
    });

    const now = new Date();
    const formattedDate = `${now.getDate().toString().padStart(2, '0')}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getFullYear().toString().substr(-2)} ${now.getHours() % 12 || 12}:${now.getMinutes().toString().padStart(2, '0')} ${now.getHours() >= 12 ? 'PM' : 'AM'}`;
    formData.append("lastUploadedDate", formattedDate);
    formData.append("projectId", projectId);
    formData.append("email", hashEmail(projectEmail));
    formData.append("projectName", projectName);

    try {
      await apiInstance.post("/api/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      toast.success("All files uploaded successfully");

      // Clear the uploaded files from state
      setSelectedFiles((prevFiles) => ({
        ...prevFiles,
        [projectId]: [],
      }));

      // Call onUploadSuccess to update lastUploadedDate in ArtistDashboard
      if (onUploadSuccess) {
        onUploadSuccess(projectId, formattedDate);
      }
    } catch (error) {
      toast.error("Error uploading files");
    }
  };

  const hashEmail = (email) => {
    let hashedEmail = "";
    for (let i = 0; i < email.length; i++) {
      hashedEmail += email.charCodeAt(i).toString(16);
    }
    return hashedEmail;
  };

  return (
    <div>
      {selectedFiles[projectId] && selectedFiles[projectId].length > 0 ? null : (
        <div {...getRootProps()} className="dropzone">
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>Drag 'n' drop some files here, or click to select files</p>
          )}
        </div>
      )}
      <div className="images-preview-container">
        {selectedFiles[projectId]?.map((file, index) => (
          <div key={index} className="image-preview-container">
            <img src={file.preview} alt={`preview ${index}`} className="image-preview" />
            <button onClick={(e) => removeFile(file.name, projectId, e)} className="remove-image-icon">
              &times;
            </button>
          </div>
        ))}
        {selectedFiles[projectId] && selectedFiles[projectId].length > 0 && (
          <button onClick={(e) => uploadFiles(e, projectId, projectEmail, projectName)} className="upload-button">
            Upload
          </button>
        )}
      </div>
    </div>
  );
};

export default ProjectDropzone;
